export const performerConfig = {
    headings: [
        {
            headingLabel: "Performer",
            icon: "sort-alpha-down",
            inputType: "text",
            width: "15%",
            border: true,
            isAlphabetVar: true,
            dataNode: "performer_name",
            placeholder: "Search Performer"
        },
        {
            headingLabel: "Role#",
            icon: "",
            width: "8%",
            inputType: "text",
            border: false,
            dataNode: "role_no",
            placeholder: "Search Role#"
        },
        {
            headingLabel: "Role Name(s)",
            icon: "",
            width: "15%",
            inputType: "text",
            border: false,
            dataNode: "role_name",
            placeholder: "Search Role Name"
        },
        {
            headingLabel: "Union",
            icon: "",
            width: "8%",
            inputType: "text",
            //inputType: "select",
            //filterType: 'searchselect',
            //filterOptions: [],
            // selectOptions: [{label:"test union", value: "test union"}, {label:"UNION 1", value: "UNION 1"}, {label:"UNION 2", value: "UNION 2"}, {label:"UNION 3", value: "UNION 3"}],
            // filterOptions: [{label:"test union", value: "test union"}, {label:"UNION 1", value: "UNION 1"}, {label:"UNION 2", value: "UNION 2"}, {label:"UNION 3", value: "UNION 3"}],
            dataNode: "union",
            border: false,
            placeholder: "Search Union"
        },
        // {
        //     headingLabel: "Production Company",
        //     icon: "",
        //     width: "13%",
        //     inputType: "select",
        //     filterType:'searchselect',
        //     // selectOptions: [{label:"test union", value: "test union"}, {label:"UNION 1", value: "UNION 1"}, {label:"UNION 2", value: "UNION 2"}, {label:"UNION 3", value: "UNION 3"}],
        //     filterOptions: [],
        //     dataNode: "production_company",
        //     border: false
        // },
        {
            headingLabel: "Deal Date",
            icon: "",
            width: "7.7%",
            inputType: "datefield",
            border: false,
            dataNode: "deal_date",
            placeholder: "mm/dd/yyyy"
        },
        {
            headingLabel: "Contracts",
            icon: "",
            width: "28%",
            //inputType: "select",
            //filterType: 'searchselect',
            // filterOptions: [],
            inputType: "text",
            //filterOptions: [{ text: "test union", value: "test union" }, { text: "UNION 1", value: "UNION 1" }, { text: "UNION 2", value: "UNION 2" }, { text: "UNION 3", value: "UNION 3" }],
            dataNode: "contracts",
            border: false,
            placeholder: "Search Contract"
        },
        {
            headingLabel: "Performer Notes",
            icon: "",
            width: "18.3%",
            inputType: "text",
            border: true,
            dataNode: "performer_notes",
            placeholder: "Search Notes"
        }
    ],
    dataNodes: ["selected_alias_name", "role_no", "role_name", "union", "deal_date", "contract_list", "performer_notes"],
    //dataNodes: ["performer_name", "role_no", "role_name", "union", "production_company", "deal_date", "contract_list", "performer_notes"],
    // borders: ["performer_name", "role_no", "performer_notes"],
    primaryKey: "pse_performer_id",
    hyperlinks: ['selected_alias_name'],
    //dataNodeGroups: {"performer_name": ["aka"]},
    filtersLanding: true,
    dataNodeGroups: { "selected_alias_name": [{ nodeHeader: 'PKA : ', nodeValue: "pka_name", textColor: null }] },
    identityKey: "performerTable",
    // isSearchBar: true,
    filters: true,
};

export const performersTabs = [
    { label: "Names/Project Details", index: 0, name: "projectDetails", checkFlag: null },
    { label: "Contacts & Payments", index: 1, name: "contactsPayments", checkFlag: 'has_payments' },
    { label: "Compensation", index: 2, name: "compensation", checkFlag: 'has_contracts' },
    { label: "Credits", index: 3, name: "credits", checkFlag: 'has_credits' },
    { label: "Perks", index: 4, name: "perks", checkFlag: 'has_perks' },
    { label: "Work Activity", index: 5, name: "workActivity", checkFlag: 'has_work_activity' },
    { label: "Files", index: 6, name: "files", checkFlag: null },
    { label: "Summary", index: 7, name: "summary", checkFlag: null }
]

// {
//     "performer_deal_representative_id": 1,
//     "is_send_payment_to": "0",
//     "is_update_representative_profile":"1",
//     "type": "REPRESENTATIVE",
//     "performer_deal_id": 6,
//     "representative_id": 3594,
//     "selected_alias_name": "Josha",
//     "agency_id": 594,
//     "agency_name": "CREATIVE ARTISTS AGENCY",
//     "location": "LOS ANGELESq",
//     "address": [
//         {
//             "performer_deal_representative_address_id": 1,
//             "is_delete": "0",
//             "representative_id": 3594,
//             "address_type": "Suite",
//             "address": "fsdf wwww",
//             "city": "sas",
//             "state": "asas",
//             "zip": "sasas",
//             "country": "in",
//             "is_primary": "1"
//         },
//         {
//             "performer_deal_representative_address_id": 2,
//             "is_delete": "0",
//             "representative_id": 3594,
//             "address_type": "Suite",
//             "address": "dsd ddsdsds",
//             "city": null,
//             "state": null,
//             "zip": null,
//             "country": null,
//             "is_primary": "1"
//         }
//     ],
//     "email": [
//         {
//             "performer_deal_representative_email_id": 1,
//             "representative_id": 3594,
//             "email": "abc123@mail.com",
//             "email_type": "HOMEa",
//             "is_primary": "1"
//         },
//         {
//             "performer_deal_representative_email_id": 2,
//             "representative_id": 3594,
//             "email": "abc1aa@mail.com",
//             "email_type": "Home1a",
//             "is_primary": "1"
//         }
//     ],
//     "phone": [
//         {
//             "performer_deal_representative_phone_id": 1,
//             "is_delete": "0",
//             "representative_id": 3594,
//             "phone_type": "work",
//             "phone": "1",
//             "ext": 1234555,
//             "is_primary": "1"
//         },
//         {
//             "performer_deal_representative_phone_id": 2,
//             "is_delete": "0",
//             "representative_id": 3594,
//             "phone_type": "work2",
//             "phone": "1",
//             "ext": 1111,
//             "is_primary": "1"
//         }
//     ]
// }

export const initialObjectConfig = {
    object_id: null,
    object_type: null,
    value: null,
    is_primary: 0,
    is_delete: 0,
    ext: null
}

export const phoneObjData = {
    "phone_id": null,
    "phone_type": null,
    "phone": null,
    "ext": null,
    "is_primary": 0,
    "is_delete": 0,
    "index": 1
}

export const occupationList = [
    {
        label: "Personal",
        value: "Personal"
    },
    {
        label: "Agent",
        value: "Agent"
    },
]

export const locationList = [
    {
        label: "LOS ANGELESq",
        value: "LOS ANGELESq"
    },
    {
        label: "New York",
        value: "New York"
    },
]

export const companyList = [
    {
        label: "CREATIVE ARTISTS AGENCY",
        value: "CREATIVE ARTISTS AGENCY"
    },
    {
        label: "COGNIZANT",
        value: "COGNIZANT"
    },
]

export const talentList = [
    {
        label: "Talent1",
        value: "Talent1"
    },
    {
        label: "Talent2",
        value: "Talent2"
    },
    {
        label: "Talent3",
        value: "Talent3"
    },
]

export const addressList = [
    {
        label: "Address1",
        value: "Address1"
    },
    {
        label: "Address2",
        value: "Address2"
    },
    {
        label: "Address2",
        value: "Address2"
    },
]

export const WorkActivityGuaranteeOptions = [
    {
        value: null,
        label: " "
    },
    {
        value: 1,
        text: "1"
    }
]

export const WorkActivityBasicOptions = [
    {
        value: null,
        label: " "
    },
    {
        value: 0.5,
        text: "0.5"
    },
    {
        value: 1,
        text: "1"
    }
]

export const workActivityConfig = {
    "performer_deal_work_activity_id": null,
    "is_delete": 0,
    "performer_deal_id": null,
    "performer_deal_contract_id": null,
    "code": null,
    "description": null,
    "guarantee": null,
    "principal": null,
    "post": null,
    "basic": null,
    "text": null,
    "contract_date": null,
    "start_date": null,
    "guarentee_period": null,
    "principal_free_period": null,
    "post_free_period": null
}