import { MDBContainer, MDBRow, MDBCol, MDBModalDialog, MDBModalBody, MDBModalContent, MDBModal, MDBIcon } from 'mdb-react-ui-kit';
import React, { Component } from 'react'; //, { useState }
import CasterService from '../../../services/service';
import SearchSelectField from '../SharedComponents/SearchSelectField';
import CancelIcon from '@material-ui/icons/Cancel';
import SelectField from '../SharedComponents/SelectField/SelectField';
import DateField from '../SharedComponents/DateField/DateField';
import BasicTextField from '../SharedComponents/BasicTextField/BasicTextField';
import BasicTextArea from '../SharedComponents/BasicTextArea/BasicTextArea';
import BasicCheckbox from '../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicButton from '../SharedComponents/BasicButton/BasicButton';
import defaultImg from '../../assets/defaultImgWhite.png';
import * as Constants from '../../../constants/constants';
import BasicLabel from '../SharedComponents/BasicLabel/BasicLabel';
import { withUserContext } from "../../../contexts/UserContext";
import { uploadProfileParams } from '../../Common/DefaultModalLayout/ProfilePicture/Config';
import ProfilePictureUpload from '../../Common/DefaultModalLayout/ProfilePicture/ProfilePictureUpload';
import { CircularProgress } from '@material-ui/core'
import MessageModal from '../../components/SharedComponents/MessageModal';
import { ssnFormat, getFormattedDate, checkLoanoutEIDNum, formatLoanoutEIDNum, checkSSNNum, formatSSNNum, checkFederalIDNum, formatEIDNum } from '../../Common/Helper';
import AliasComponent from '../../Common/AliasComponent/AliasComponent';
import { ssnOptions, initialAliasConfig } from '../Talent/Config';
import SizeLimits from "../../Common/SizeLimits.json"
import SelectAndInputCombine from '../../Common/SelectAndInputCombine/SelectAndInputCombine';
import Messages from '../../Common/Messages.json'
import SearchFieldWithAddValue from '../SharedComponents/SearchFieldWithAddValue';
import moment from 'moment';
import Modal from "../../Common/MainViewModalLayout/Modal/Modal";
import RichTextEditor from '../SharedComponents/RichTextEditor/RichTextEditor';
let alias_first_name, alias_last_name, alias_middle_name, alias_suffix = null
class TalentPerformer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projectDetailsLoading: false,
            performerDetails: null,
            alias_options: [],
            countryOptions: [],
            stateOptions: [],
            stateOptionsWithoutCode:[],
            immigrationStatusOptions: [],
            companyNames: [],
            sagStatusOptions: [],
            i9StatusOptions: [],
            imgMode: "download",
            profileImg: null,
            isProfileChanged: false,
            imageType: null,
            postInitiated: false,
            showErrorField: false,
            fieldChanged: false,
            showError: false,
            showFail: false,
            showSuccess: false,
            saveTitle: "Success",
            saveEditMessage: "The changes are saved successfully!",
            failTitle: "Failure",
            failMessage: "Saving changes failed!",
            unsavedTitle: "Unsaved Changes",
            unsavedMessage: "Are you sure you want to discard your changes?",
            genderOptions: [],
            ethnicityOptions: [],
            loanoutOptions: [],
            isSSNVisible: false,
            ssnOptions: [...ssnOptions],
            disableSave: true,
            sagErrorMessage: "Duplicate SAG.",
            loanoutErrorMesg: "Duplicate Loanout No.",
            invalidSSN: false,
            monthsAge: null,
            addedAliases: this.props?.isNew ? [] : this.props?.performerDetails?.addedAliases || [],
            openEditPerformerModalContact: false
        }
    }

    componentDidMount() {
        if (this.props?.isNew || this.props?.existingTalent) {
            this.getTalentDetails(this.props?.selectedTalent?.value);
        } else {
            this.setState({
                performerDetails: this.props?.performerDetails,
                loanoutOptions: this.props?.loanoutOptions,
                alias_options: this.props?.alias_options,
                legalList: this.props?.legalList
            }, () => {
                this.saveButtonState();
                this.getLookupValues('Country');
                this.getCompanyDetails("PROJECT_PRODUCTION_COMPANIES_UNION", this.props?.performerDetails.production_company?.value, true)
            })
        }
        this.getLookupValues('IMMIGRATION_STATUS');
        this.getLookupValues('State',"withCode");
        this.getLookupValues('State',"withoutCode");
        // this.getLookupValues('UNION');
        this.getLookupValues('SAG_STATUS');
        this.getLookupValues('I9_STATUS');
        this.getLookupValues('Gender');
        this.getLookupValues('Ethnicity');
        // this.getLookupValues('LOANOUT_COMPANIES');
    }

    getTalentDetails = (talent_id) => {
        this.setState({ projectDetailsLoading: true })
        CasterService.getData(Constants.CasterServiceBaseUrl +
            `/casterFeatureTalentDetails?id=${talent_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                console.log("response", response)
                if (response?.data?.error || !response?.data) {
                    this.setState({ projectDetailsLoading: false })
                    console.log("Error fetching talent Setails");
                }
                else {
                    let primary_loanout_details = response.data[0].legal_list.find(i => i.is_primary === 1)
                    let loanoutOptions = response.data[0].legal_list?.map(i => ({ text: i?.loanout_company, value: i?.loanout_company }))
                    // let talentName = `${response.data[0].first_name? response.data[0].first_name : ''} ${response.data[0].middle_name ? response.data[0].middle_name : ''} ${response.data[0].last_name ? response.data[0].last_name : ''} ${response.data[0].suffix ? response.data[0].suffix : ''}`
                    let talentName = this.props?.isNew ? this.props?.selectedTalent?.headerName : this.props?.selectedTalent?.talent_name
                    let alias_options = [{ value: talentName, label: talentName }]
                    if (response.data[0]?.akas.length > 0) {
                        for (let x in response.data[0]?.akas) {
                            alias_options.push({ value: `${talentName} PKA ${response.data[0]?.akas[x].aka}`, label: `${talentName} PKA ${response.data[0]?.akas[x].aka}` })
                        }
                        for (let x in response.data[0]?.akas) {
                            alias_options.push({ value: `${response.data[0]?.akas[x].aka}`, label: `${response.data[0]?.akas[x].aka}` })
                        }
                    }                  
                    let talent_details = response.data[0]
                    // let dateToday = new Date()
                    let formattedList = {
                        "performer_deal_id": null,
                        "is_delete": "0",
                        "project_id": this.props?.project_id,
                        "selected_alias_name": this.props?.isNew ? this.props?.selectedTalent?.headerName : this.props?.selectedTalent?.name_pka || null,
                        "immigration_status": talent_details.immigration_status,
                        "expiration_date": talent_details.expiration_date,
                        "deal_date": moment(new Date())?.format("YYYY-MM-DD"),
                        "role_no": null,
                        "role": null,
                        "production_company": this.props?.projectProdCompanies?.length === 1 ? this.props?.projectProdCompanies[0] : null,
                        "union": null,
                        "sag_status": null,
                        "i9_status": null,
                        "sag_id": talent_details.sag_id,
                        "loanout_company": primary_loanout_details === undefined ? null : primary_loanout_details?.loanout_company == undefined,
                        "loanout_company_state": primary_loanout_details === undefined ? null : { text: primary_loanout_details?.loanout_company, value: primary_loanout_details?.loanout_company },
                        "state_inc": primary_loanout_details === undefined ? null : { text: primary_loanout_details?.state_inc?.includes("-") ? primary_loanout_details?.state_inc?.split("-")?.[1] : primary_loanout_details?.state_inc, value: primary_loanout_details?.state_inc?.includes("-") ? primary_loanout_details?.state_inc?.split("-")?.[1] : primary_loanout_details?.state_inc },
                        "country_inc": primary_loanout_details === undefined ? null : { text: primary_loanout_details?.country_inc, value: primary_loanout_details?.country_inc },
                        "loanout_no": primary_loanout_details?.federal_id_no,
                        "comments": null,
                        "talent_id": talent_details?.talent_id,
                        "first_name": talent_details?.first_name,
                        "last_name": talent_details?.last_name,
                        "middle_name": talent_details?.middle_name,
                        "suffix": talent_details?.suffix,
                        "ssn": talent_details?.ssn,
                        "masked_ssn": talent_details?.masked_ssn,
                        "f_id": talent_details?.f_id,
                        "ssn_type": 'ssn',
                        "gender": talent_details?.gender,
                        "ethnicity_list": talent_details?.ethnicity_list?.length > 0 ?
                            talent_details?.ethnicity_list?.map(i => {
                                return {
                                    id: i?.talent_ethnicity_id,
                                    value: i?.ethnicity,
                                    text: i?.ethnicity
                                }
                            }) : [],
                        "birth_date": talent_details?.birth_date,
                        "death_date": talent_details?.death_date,
                        "citizen_of": talent_details?.citizen_of,
                        "as_of": talent_details?.as_of,
                        "expiration_date": talent_details?.expiry_date || talent_details?.expiration_date,
                        "talent_notes": talent_details?.notes,
                        "talent_is_active": talent_details?.is_active,
                    }

                    this.setState({ projectDetailsLoading: false, performerDetails: formattedList, alias_options: alias_options, loanoutOptions: loanoutOptions, legalList: response.data[0].legal_list, addedAliases: talent_details.aka }, () => {
                        this.getAge(formattedList.birth_date);
                        this.getLookupValues('Country');
                        this.saveButtonState();
                        if (this.props?.projectProdCompanies?.length === 1 && this.state.performerDetails.production_company)
                            this.getCompanyDetails("PROJECT_PRODUCTION_COMPANIES_UNION", this.state.performerDetails.production_company?.value, true)
                    })
                }
            },
                (err) => {
                    this.setState({ projectDetailsLoading: false })
                    console.log("Error in fetching Talent Details:", err)
                })
    }

    getLookupValues(type,format=null) {
        // casterFeatureLookup
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = type != "LOANOUT_COMPANIES" ? response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value })) : response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
                    if (type == 'Country') {
                        let formattedListCountry = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
                        let defaultCountry = formattedListCountry.filter(item => {
                            return item.label === "United States" || item.label === "USA" || item.label === "US"
                        })
                        this.setState((prevState) => ({
                            performerDetails: {
                                ...prevState?.performerDetails,
                                ["country_inc"]: this.state.performerDetails?.country_inc
                            },
                            countryOptions: formattedListCountry,
                            countryOptionsCitizen: formattedList
                        }))
                        // this.setState({ countryOptions: formattedList })
                    } else if (type == 'State') {
                        if(format==="withCode"){
                            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[0] : item.lookup_value, text: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[0] : item.lookup_value }));
                            this.setState({ stateOptions: formattedList })
                          }
                          if(format==="withoutCode"){
                            formattedList = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[1] : item.lookup_value, text: item.lookup_value?.includes("-") ? item.lookup_value?.split("-")?.[1] : item.lookup_value }))
                            this.setState({ stateOptionsWithoutCode: formattedList })
                          }
                    } else if (type == 'IMMIGRATION_STATUS') {
                        let formattedListImStatus = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
                        this.setState({ immigrationStatusOptions: formattedListImStatus })
                    } //else if(type == 'UNION') {
                    //this.setState({ unionOptions: formattedList})
                    // } 
                    else if (type == 'SAG_STATUS') {
                        this.setState({ sagStatusOptions: formattedList })
                    } else if (type == 'I9_STATUS') {
                        this.setState({ i9StatusOptions: formattedList })
                    } else if (type == 'Gender') {
                        this.setState({ genderOptions: formattedList })
                    } else if (type == 'Ethnicity') {
                        let formattedListEthnicity = response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, text: item?.lookup_value }))
                        this.setState({ ethnicityOptions: formattedListEthnicity })
                    }// else if(type == "LOANOUT_COMPANIES") {
                    //     this.setState({ loanoutOptions: formattedList })
                    // }
                }
            }, err => {
                console.log("error from lookup")
            })

    }

    getCompanyDetails = (objectType, searchString, onMount = false) => {
        // this.setState({ loading: true });
        // let inputParam= ({"project_id": this.props?.project_id})
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}&project_id=${this.props?.project_id}`, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("Error while fetching data")
                }
                else {
                    let performerDetails = this.state.performerDetails
                    let formattedList = []
                    if (objectType === "PROJECT_PRODUCTION_COMPANIES_UNION") {
                        formattedList = response.data?.map(item => ({ value: item?.union, label: item?.union }));
                        if (formattedList.length === 1) {
                            performerDetails.union = formattedList[0].value
                        }
                        this.setState({ unionOptions: formattedList })
                    } else {
                        formattedList = response.data?.map(item => ({ value: item?.production_company, text: item?.production_company, id: item?.production_company_id }));
                    }
                    this.setState({ companyNames: formattedList, loading: false, performerDetails: performerDetails }, () => {
                        this.setState({ fieldChanged: true })
                        this.saveButtonState()
                        onMount ? "" : this.props?.fieldChanged(true, "change");
                    });
                }
            },
                (err) => {
                    console.log("Error in fetching Company Details:", err)
                })
    }
    handleOnChangeText = (value) => {
        this.onChange("comments", value);
    }
    onChange = (field, value) => {
        if (field === "ethnicity_list") {
            let newValue = value.filter(
                (value, index, arr) =>
                    index === arr.findIndex((item) => item.value === value.value)
            );
            this.setState((prevState) => ({
                performerDetails: {
                    ...prevState?.performerDetails,
                    [field]: newValue
                }
            }), () => {
                this.setState({ fieldChanged: true })
                this.saveButtonState()
                this.props?.fieldChanged(true, "change");
            })
        } else {
            let newValue = value == "" ? null : value, age=null;
            if (typeof value === "boolean") {
                newValue = value ? 1 : 0
            }
            this.setState(prevState => ({
                performerDetails: {
                    ...prevState.performerDetails,
                    [field]: field === "ethnicity_list" ? checkValue : newValue,
                    ...(field == 'birth_date') && { 'age': this.getAge(newValue) },
                    ...(field == 'death_date') && { 'age': this.getAge(newValue,"death_date") },
                    ...(field == 'ssn') && { 'masked_ssn': maskSsn(newValue) },
                    ...(field == 'ssn_type') && { 'ssn': null, 'f_id': null },
                },
                postInitiated: false,
                // ssnError: false,
                // sagError: false
            }), () => {
                if (field === "birth_date" && (value === null || value === "")) {
                    this.setState(prevState => ({
                        performerDetails: {
                            ...prevState.performerDetails,
                            death_date:null
                        }
                    }))
                }
                if (field === "production_company") {
                    let performerDetails = this.state.performerDetails
                    // performerDetails.production_company_state = null
                    performerDetails["project_production_company_id"]= value?.project_production_company_id;
                    this.setState({ performerDetails: performerDetails })
                    this.getCompanyDetails("PROJECT_PRODUCTION_COMPANIES_UNION", value?.value, false)
                }
                if (field === "country_inc") {
                    let performerDetails = this.state.performerDetails
                    performerDetails.state_inc = null
                    this.setState({ performerDetails: performerDetails })
                } if (field === "state_inc") {
                    let performerDetails = this.state.performerDetails
                    let defaultCountry = this.state.countryOptions.find(item => {
                        return item.text === "United States" || item.text === "USA" || item.text === "US"
                    })
                    performerDetails.country_inc = defaultCountry
                    this.setState({ performerDetails: performerDetails })
                }
                this.setState({ fieldChanged: true, ssnError: false, sagError: false, loanoutError: false, invalidSSN: false })
                this.saveButtonState()
                this.props?.fieldChanged(true, "change");
            })
        }
    }

    saveButtonState = () => {
        let performerDetails = this.state.performerDetails
        performerDetails.talent_legal_id = this.state.legalList?.find(item => item.loanout_company === loanout_company)?.talent_legal_id
        let disableSave = this.props?.existingTalent ? (this.state.performerDetails?.deal_date != null &&
            (this.state.performerDetails?.production_company != null) && this.state.performerDetails?.union != null
            // && this.state.performerDetails?.sag_id
        ) :
            this.props?.isNew ? (
                // (this.state.performerDetails?.ssn_type === 'ssn' ? this.state.performerDetails?.ssn != null : true)  && 
                this.state.performerDetails?.ethnicity_list?.length > 0 && this.state.performerDetails?.deal_date != null &&
                (this.state.performerDetails?.production_company != null) && this.state.performerDetails?.union != null
                //  && this.state.performerDetails?.sag_id
            )
                : (this.state.performerDetails?.deal_date != null &&
                    (this.state.performerDetails?.production_company != null) && this.state.performerDetails?.union != null
                    // && this.state.performerDetails?.sag_id
                )
        this.setState({ disableSave: !disableSave })
    }

    checkDuplicate = async (objectType, searchString, checkExistingKey, talent_id) => {
        await CasterService.getData(
            Constants.CasterServiceBaseUrl +
            `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}&check_existing_key=${checkExistingKey}&talent_id=${talent_id}`,
            this.props.userContext?.active_tenant?.tenant_id
        )
            .then(response => {
                if (response?.data) {
                    let ssnError = response?.data[0]?.entity_check_name === "SSN" ?
                        response?.data[0]?.entity_check_result == 1 ? true : false : false
                    let sagError = response?.data[1]?.entity_check_name === "SAG_ID" ?
                        response?.data[1]?.entity_check_result == 1 ? true : false : false
                    //let loanoutError = response?.data[2]?.entity_check_name === "LOANOUT_NO" ?
                        //response?.data[2]?.entity_check_result == 1 ? true : false : false
                    this.setState({ ssnError: ssnError, sagError: sagError })
                    // return true;
                    // (ssnError || sagError || loanoutError);
                }
            },
                (err) => {
                    console.log(err, "error in option")
                    // return false;
                })
    }

    validateErrorFlags = () => {
        let canSave = (this.state.performerDetails?.deal_date != null ? true : false) &&
            (this.state.performerDetails?.production_company != null ? true : false) && (this.state.performerDetails?.union != null ? true : false) &&
            (this.props?.isNew ? this.state.performerDetails?.ssn_type === 'ssn' ? ((this.state.performerDetails?.ssn ? this.state.performerDetails?.ssn?.length == SizeLimits?.formattedSSN && this.state.performerDetails?.ssn?.length == SizeLimits?.formattedSSN ? checkSSNNum(this.state.performerDetails?.ssn) : false : true) ? true : false) && (this.state.performerDetails?.ethnicity_list?.length > 0 ? true : false) : true && (this.state.performerDetails?.loanout_company_state?.value ? this.state.performerDetails?.loanout_no?.length > 0 ? (this.state.performerDetails?.country_inc?.value === 'United States' || this.state.performerDetails?.country_inc?.value === 'USA' || this.state.performerDetails?.country_inc?.value === 'US' ? (this.state.performerDetails?.loanout_no?.length == SizeLimits?.intlZipCharacterLimit && (this.state.performerDetails?.loanout_no?.length == SizeLimits?.intlZipCharacterLimit ? checkFederalIDNum(this.state.performerDetails?.loanout_no) : false)) : false) : true : true) : true)
        return canSave;
        // let canSave = (this.state.performerDetails?.deal_date != null ? true : false) &&
        //     (this.state.performerDetails?.production_company != null ? true : false) && (this.state.performerDetails?.union != null ? true : false) &&
        //     (this.props?.isNew ? this.state.performerDetails?.ssn_type === 'ssn' ? (this.state.performerDetails?.ssn != null && (this.state.performerDetails?.ssn.length == SizeLimits?.formattedSSN && this.state.performerDetails?.ssn?.length == SizeLimits?.formattedSSN ? checkSSNNum(this.state.performerDetails?.ssn) : false) ? true : false) && (this.state.performerDetails?.ethnicity_list?.length > 0 ? true : false) && (this.state.performerDetails?.sag_id != null ? true : false) : true && (this.state.performerDetails?.loanout_company_state?.value ? this.state.performerDetails?.loanout_no?.length > 0 ? (this.state.performerDetails?.country_inc?.value === 'United States' || this.state.performerDetails?.country_inc?.value === 'USA' || this.state.performerDetails?.country_inc?.value === 'US' ? (this.state.performerDetails?.loanout_no?.length == SizeLimits?.intlZipCharacterLimit && (this.state.performerDetails?.loanout_no?.length == SizeLimits?.intlZipCharacterLimit ? checkFederalIDNum(this.state.performerDetails?.loanout_no) : false)) : false) : true : true) : true)
        // return canSave;
        // let canSave = (this.state.performerDetails?.deal_date != null ? true : false) &&
        //     (this.state.performerDetails?.production_company != null ? true : false) && (this.state.performerDetails?.union != null ? true : false) &&
        //     this.props?.isNew && this.state.performerDetails?.ssn_type === 'ssn' ? (this.state.performerDetails?.ssn != null && (this.state.performerDetails?.ssn.length == SizeLimits?.formattedSSN && this.state.performerDetails?.ssn?.length == SizeLimits?.formattedSSN ? checkSSNNum(this.state.performerDetails?.ssn) : false) ? true : false) && (this.state.performerDetails?.ethnicity_list?.length > 0 != null ? true : false) && (this.state.performerDetails?.sag_id != null ? true : false) : true && (this.state.performerDetails?.loanout_company_state?.value ? this.state.performerDetails?.loanout_no?.length > 0 ? (this.state.performerDetails?.country_inc?.value === 'United States' || this.state.performerDetails?.country_inc?.value === 'USA' || this.state.performerDetails?.country_inc?.value === 'US' ? (this.state.performerDetails?.loanout_no?.length == SizeLimits?.intlZipCharacterLimit && (this.state.performerDetails?.loanout_no?.length == SizeLimits?.intlZipCharacterLimit ? checkFederalIDNum(this.state.performerDetails?.loanout_no) : false)) : false) : true : true)
        // return canSave;
    }

    postTalentPerformer = async (tempPostJson) => {
        this.setState({ postingTalentPerformer: true, postInitiated: true })
        if (this.props?.isNew || !this.props?.isNew) {
            await this.checkDuplicate('CHECK_EXISTING', `${this.state.performerDetails?.ssn ? this.state.performerDetails?.ssn : ""}${this.state.performerDetails?.sag_id ? ',' + this.state.performerDetails?.sag_id : ',' + ""}`, "SSN,SAG_ID", this.state.performerDetails?.talent_id)
            if (this.state.ssnError || this.state.sagError) {
                this.setState({ postInitiated: false, postingTalentPerformer: false })
                return;
            }
        }
        if (!this.validateErrorFlags()) {
            this.setState({ postInitiated: false, showErrorField: true, postingTalentPerformer: false })
            return;
        }
        let postJson = JSON.parse(JSON.stringify(tempPostJson));
        postJson.production_company = postJson.production_company?.value
        postJson.loanout_company = postJson.loanout_company_state?.value
        postJson.loanout_no = postJson.loanout_no?.toString();
        postJson.aliases = this.state.addedAliases,
            postJson.selected_alias_name = postJson.selected_alias_name?.replace("  ", " ")
        postJson.lead_flag = (postJson?.lead_flag == true || postJson?.lead_flag == 1) ? 1 : 0
        postJson.is_complete = postJson?.performer_deal_id ? postJson?.is_complete : 0
        postJson.immigration_status = postJson?.immigration_status?.value
        postJson.state_inc = (postJson?.country_inc?.value === 'United States' || postJson?.country_inc?.value === 'USA' || postJson?.country_inc?.value === 'US') ? postJson?.state_inc?.value : null
        postJson.country_inc = postJson?.country_inc?.value
        if (this.props?.isNew || this.props?.existingTalent) {
            postJson.ethnicity_list = postJson.ethnicity_list?.map(item => ({
                ethnicity: item?.text,
                talent_ethnicity_id: item?.id,
                is_primary: 0,
                is_delete: 0
            }))
        }
        let age = this.getAge(postJson.birth_date);
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDeal`, postJson, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data.error) {
                    console.log("Error posting Talent Performer Details data");
                    this.setState({ postingTalentPerformer: false, postInitiated: false, showErrorField: false }, () => {
                        this.props?.fieldChanged(true, "fail");
                    });
                }
                else {
                    if (this.state.isProfileChanged) {
                        this.uploadProfile(response.data[0].performer_deal_id);
                    }
                    this.setState({ postingTalentPerformer: false, postInitiated: false, showErrorField: false, addedAliases: [] }, () => {
                        if (this.props?.advSearch) {
                            this.props?.handleClose();
                        } else {
                            this.props?.fieldChanged(true, "success");
                            this.props?.handleClose();
                            if (this.state.age < 18 && postJson.birth_date != null && this.props?.performerDetails?.has_guardians != 1) {
                                this.props?.viewPerformerData();
                                this.props?.getTalentPerformerDetails(response.data[0]?.performer_deal_id);
                                this.props?.newPerformerMinor()
                            }
                            else {
                                this.props?.viewPerformerData();
                                this.props?.getTalentPerformerDetails(response.data[0]?.performer_deal_id);
                            }
                        }
                    });
                    console.log("Posted successfully", this.state.performerDetails)
                }
            },
                (err) => {
                    this.setState({ postingTalentPerformer: false, postInitiated: false, showErrorField: false }, () => {
                        this.props?.fieldChanged(true, "fail");
                    });
                    console.log("Error posting Talent Perfomer data", err)
                })
    }

    generateFilePayload = (id) => {
        let objectType = "Talent"
        let performer_deal_id = this.props?.isNew || this.props?.existingTalent ? id : this.props?.performer_deal_id
        // let performer_deal_id = this.props?.performer_deal_id ? this.props?.performer_deal_id : id
        let fileName = `talent_${this.state?.performerDetails?.talent_id ? this.state?.performerDetails?.talent_id : this.props?.performerDetails?.talent_id}`
        let tenantName = this.props.userContext?.active_tenant?.tenant_name;
        let uploadPayload = {
            ...uploadProfileParams,
            signedURLType: this.state.imgMode,
            fileCategory: "profileimage",
            objectType: objectType,
            objectId: this.state?.performerDetails?.talent_id ? this.state?.performerDetails?.talent_id : this.props?.performerDetails?.talent_id,
            contentType: this.state.imageType || null,
            fileName,
        };
        return uploadPayload;
    }
    convertBlobToImg = (blb) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result;
            this.setState({ profileImg: text, imgMode: "download" });
        });
        reader.readAsText(blb);
    }
    handleImgMode(mode) {
        this.setState({ imgMode: mode })
    }
    handleProfileChange(val) {
        this.setState({ isProfileChanged: val })
    }
    handleProfileImg(file) {
        this.setState({ profileImg: file })
    }
    handleImageType(val) {
        let type = val.slice(6);
        this.setState({ imageType: type })

    }
    uploadProfile = (id) => {
        let uploadPayload = this.generateFilePayload(id);
        let file = this.state.profileImg;
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + '/casterFeaturePreSignedUrlUpload', uploadPayload, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (response.data && response.data != []) {
                    if (this.state.imgMode === "upload") {
                        CasterService.uploadFile(response.data, file).then(response => {
                            this.setState({ imgMode: "download", isProfileChanged: false });
                        }, (err) => {
                            this.setState({ imgMode: "download", isProfileChanged: false, profileImg: null });
                            console.log("File Upload Failed:" + err);
                        });
                    }
                    else {
                        CasterService.downloadFile(response.data).then(res => {
                            // let imageType = response.data.Key.split('.')
                            // imageType = imageType[1] || null
                            // this.setState({ imageType: imageType });
                            this.convertBlobToImg(res.data);
                        }, (err) => {
                            console.log("File Download Failed:" + err);
                            this.setState({ profileImg: null, imgMode: 'download' })
                        });
                    }
                }
            }, (err) => {
                console.log("Pre Signed Url Failed:" + err);
            });
    }

    removeProfileImg = () => {
        this.setState({ imgMode: "" }, () => {
            let payload = this.generateFilePayload(this.props?.id);
            let file = this.state.profileImg;
            payload.filename = payload.fileName
            CasterService.deleteDataParams(Constants.CasterServiceBaseUrl + '/casterFeatureDeleteFiles', payload, this.props.userContext?.active_tenant?.tenant_id)
                .then(response => {
                    if (response.data.error) {
                        // this.notificationComponent(true, "fail");
                        console.log("Profile File Removal Failed:" + err);
                    }
                    else {
                        // this.notificationComponent(true, "remove");
                        this.uploadProfile();
                    }

                }, (err) => {
                    // this.notificationComponent(true, "fail");
                    console.log("Pre Signed Url Failed:" + err);
                });
        })
    }

    // onClose = () => {
    //     if (this.state.fieldChanged) {
    //         this.setState({ showError: true });
    //     } else {
    //         this.props?.handleClose()
    //     }
    // }

    // closPopup = () => {
    //     this.props?.handleClose()
    // }

    // fieldChanged = (value, mode = "error") => {
    //     switch (mode) {
    //         case "change": return this.setState({ fieldChanged: value });
    //         case "error": return this.setState({ fieldChanged: value });
    //         case "success": return this.setState({ showSuccess: value });
    //         case "fail": return this.setState({ showFail: value });
    //     }
    // }

    formatSSN() {
        let ssn = this.state?.performerDetails?.ssn;
        if (ssn && !ssnFormat(ssn) && ssn?.length == SizeLimits?.ssnCharacterLimit) {
            let result = ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5, ssn.length);
            if (ssnFormat(result)) {
                this.onChange('ssn', result)
            }
        }
        return ssn;
    }

    onAliasSave = (value) => {
        let alias_options = [...this.state.alias_options]
        let newAlias = {
            alias_first_name: value?.aka_first_name,
            alias_last_name: value?.aka_last_name,
            alias_middle_name: value?.aka_middle_name,
            alias_suffix: value?.aka_suffix
        }
        let addedAliases = this.state.addedAliases
        addedAliases?.push(newAlias)
        alias_options?.push({ value: `${this.state.performerDetails?.talent_name ? this.state.performerDetails?.talent_name?.replace('(K)','').trim() : this.props?.selectedTalent?.talent_name?.replace('(K)','').trim()} PKA ${value.aka}`, label: `${this.state.performerDetails?.talent_name ? this.state.performerDetails?.talent_name?.replace('(K)','').trim() : this.props?.selectedTalent?.talent_name} PKA ${value.aka}` })
        alias_options?.push({ value: value.aka, label: value.aka })
        this.setState({ alias_options: alias_options, addedAliases: addedAliases }, () => {
            this.onChange("selected_alias_name", value.aka);
        })
    }

    validateLoanout(value) {
        return true;
    }

    loanOutChange = (value) => {
        let performerDetails = this.state.performerDetails
        if (value?.optionType == 'new') {
            performerDetails.loanout_company_state = { value: value?.value, text: value?.value }
            performerDetails.loanout_company = value?.value
            performerDetails.talent_legal_id = null
        } else if (value == null) {
            performerDetails.loanout_company = null,
                performerDetails.loanout_company_state = null,
                performerDetails.country_inc = null,
                performerDetails.state_inc = null,
                performerDetails.loanout_no = null,
                performerDetails.talent_legal_id = null
        } else {
            let loanout = this.state.legalList?.find(item => item.loanout_company == value?.value)
            performerDetails.loanout_company = value?.value,
                performerDetails.loanout_company_state = { value: value?.value, text: value?.value }
            performerDetails.country_inc = { value: loanout?.country_inc, text: loanout?.country_inc },
                performerDetails.state_inc = { value: loanout?.state_inc?.includes("-") ? loanout?.state_inc?.split("-")?.[1] : loanout?.state_inc, text: loanout?.state_inc?.includes("-") ? loanout?.state_inc?.split("-")?.[1] : loanout?.state_inc }
            performerDetails.loanout_no = loanout?.federal_id_no
            performerDetails.talent_legal_id = loanout.talent_legal_id
        }
        this.setState({ performerDetails: performerDetails })
    }

    getAge = (passedDate,origin=null) => {
        if (passedDate!==null) {
            let endDate=null,passedDateFormatted=null;
            if(origin==="death_date"){
                if(this.state.performerDetails?.birth_date){
                    endDate = new Date(passedDate);
                    passedDateFormatted = new Date(this.state.performerDetails?.birth_date);
                }else{
                    return 0
                }
            }else{
                if(this.state.performerDetails?.death_date){
                    endDate = new Date(this.state.performerDetails?.death_date);
                }else{
                    endDate = new Date();
                }
                passedDateFormatted = new Date(passedDate) 
            }
            let age = endDate?.getFullYear() - passedDateFormatted?.getFullYear();
            let m = endDate?.getMonth() - passedDateFormatted?.getMonth();
            if (m < 0 || (m === 0 && endDate?.getDate() < passedDateFormatted?.getDate())) {
                age--;
            }
            age = (age && age > 0) ? age : (age === 0 && age > 0) ? age : 0;
            // this.handleGeneralEdit('age', birthDate ? age : 0);
            return age;
        } else if(origin === "death_date"){
            let endDate=null,passedDateFormatted=null;
            if(this.state.performerDetails?.birth_date){
                endDate = new Date();
                passedDateFormatted = new Date(this.state.performerDetails?.birth_date);
                let age = endDate?.getFullYear() - passedDateFormatted?.getFullYear();
                let m = endDate?.getMonth() - passedDateFormatted?.getMonth();
                if (m < 0 || (m === 0 && endDate?.getDate() < passedDateFormatted?.getDate())) {
                    age--;
                }
                age = (age && age > 0) ? age : (age === 0 && age > 0) ? age : 0;
                this.setState({ age: age, monthsAge: m })
            }else{
                this.setState({ age: 0 })
            }
        }else {
            this.setState({ age: 0 })
        }
    }

    render() {
        let heading = this.props?.headerText + " " + this.props?.headerName
        let confirmModalTitle = this.state.showError ? this.state.unsavedTitle : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveTitle : "";
        let message = this.state.showError ? this.state.unsavedMessage : this.state.showFail ? this.state.failMessage :
            this.state.showSuccess ? this.state.saveEditMessage : "";
        let ethnicityValues = []
        if (!this.props?.isNew && !this.props?.existingTalent) {
            ethnicityValues = this.state?.performerDetails?.ethnicity_list?.length > 0 ? this.state?.performerDetails?.ethnicity_list?.map(item => item.ethnicity) : []
        } else if (this.props?.existingTalent) {
            ethnicityValues = this.state?.performerDetails?.ethnicity_list?.length > 0 ? this.state?.performerDetails?.ethnicity_list?.map(item => item.value) : []
        }
        return (
            <div className="performer-modal">
                {
                    <MessageModal
                        open={this.state.showError || this.state.showFail || this.state.showSuccess || false}
                        title={confirmModalTitle}
                        message={message}
                        hideCancel={this.state.showFail || this.state.showSuccess || false}
                        primaryButtonText={"OK"}
                        secondaryButtonText={"Cancel"}
                        onConfirm={() => this.closPopup()}
                        handleClose={(e) => this.setState({ showError: false, showFail: false, showSuccess: false })} />
                }
                <div className="talent-performer">
                    {this.state.projectDetailsLoading ?
                        <CircularProgress />
                        :
                        <>
                            <MDBRow className="label">
                                {this.props?.isNew ? "Information entered below will also be saved to Talent's profile" : "Updating the Immigration Status, SAG ID, or Loanout information below will update on Talent's profile."}
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={6} className="bold-row">
                                    {/* <BasicLabel text={"Selected Alias"} />
                                                <SearchSelectField
                                                    id="Selected Alias"
                                                    width='100%'
                                                    searchSelect={true}
                                                    options={this.state.alias_options}
                                                    multiple={false}
                                                    isMandatory={true}
                                                    showMandatory={this.state.showErrorField}
                                                    placeholder={"Selected Alias"}
                                                    detail_selected={this.state.performerDetails?.selected_alias_name}
                                                    valueSelected={(e, value) => this.onChange("selected_alias_name", value)}
                                                    searchText={(e) => this.searchAliasList(e.target.value)}
                                                /> */}
                                    <SelectField
                                        placeHolderText=""
                                        label="Name to display on contract"
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        options={this.state.alias_options}
                                        value={this.state.performerDetails?.selected_alias_name?.replace('(K)','').trim()}
                                        onChange={(e) => this.onChange("selected_alias_name", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={2} className="addAliasContainer">
                                {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                    <AliasComponent
                                        module_type={"Talent Performer"}
                                        initialAliasConfig={initialAliasConfig}
                                        onAliasSave={this.onAliasSave.bind(this)}
                                        aliasDetails={[]}
                                    />}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="img-row">
                                <MDBCol md={4}>
                                    <ProfilePictureUpload
                                        key={'performerImg' + this.props?.selectedTalent?.value}
                                        isPerformer={true}
                                        headerText={'Talent'}
                                        mode={'edit'}
                                        imgMode={this.state.imgMode}
                                        profileImg={this.state.profileImg}
                                        handleImgMode={this.handleImgMode.bind(this)}
                                        handleProfileChange={this.handleProfileChange.bind(this)}
                                        handleProfileImg={this.handleProfileImg.bind(this)}
                                        uploadProfile={this.uploadProfile.bind(this)}
                                        handleImageType={this.handleImageType.bind(this)}
                                        removeProfileImg={this.removeProfileImg.bind(this)}
                                        isUpload={true}
                                        talent_id={this.props?.selectedTalent?.value}
                                        isEditing={true}
                                        module={"TalentPerformer"}
                                    />
                                </MDBCol>
                                {this.props?.isNew ?
                                    <>
                                        <MDBCol md={8}>
                                            <MDBRow className="bold-row">
                                                <MDBCol md={6}>
                                                    <SelectAndInputCombine
                                                        id="ssn"
                                                        label="SSN/Foreign ID"
                                                        options={this.state.ssnOptions}
                                                        value={this.state?.performerDetails?.ssn_type}
                                                        onChange={(e) => { this.onChange("ssn_type", e.target.value) }}
                                                        inputValue={this.state?.performerDetails?.ssn_type == 'ssn' ? this.state?.performerDetails?.ssn : this.state?.performerDetails?.ssn_type == 'fid' ? this.state?.performerDetails?.foreign_id : '' || ''}
                                                        onInputChange={(e) => this.onChange(this.state?.performerDetails?.ssn_type == 'fid' ? 'foreign_id' : 'ssn', this.state?.performerDetails?.ssn_type == 'fid' ? e.target?.value : formatSSNNum(e.target.value))}
                                                        showAsLabel={false}
                                                        // isMandatory={true}
                                                        // showMandatory={this.state.showErrorField}
                                                        inputLimit={this.state?.performerDetails?.ssn_type == 'ssn' ? this.state?.performerDetails?.ssn && ssnFormat(this.state?.performerDetails?.ssn) ? SizeLimits.formattedSSN : SizeLimits.ssnCharacterLimit : undefined}
                                                        inputWarning={Messages?.ssnWarning}
                                                        infoMsg='SSN number is of 9 digit & format should be eg.xxx-xx-xxxx'
                                                        onBlur={this.state?.performerDetails?.ssn_type == 'ssn' ? this.state?.performerDetails?.ssn ? () => this.formatSSN() : undefined : undefined}
                                                        fieldValid={this.state?.performerDetails?.ssn_type == 'ssn' ? ((this.state?.performerDetails?.ssn?.length > 9 ? !checkSSNNum(this.state?.performerDetails?.ssn) ? true : false : false)) : false}
                                                        invalidInput={Messages.invalidInput}
                                                        showDuplicateSSN={this.state.ssnError}
                                                        invalidSSN={this.state.invalidSSN}
                                                    />
                                                </MDBCol>
                                                {/* <MDBCol md={3} className="headingLabel">
                                                            <SelectField 
                                                                className="ssnSelectField"
                                                                label={"SSN/Foreign ID"}
                                                                options={this.state.ssnOptions}
                                                                isMandatory={true}
                                                                showMandatory={this.state.showErrorField}
                                                            />
                                                        </MDBCol>
                                                        <MDBCol md={3} className="headingLabel ssnInputField">
                                                            <BasicTextField 
                                                                placeholder="xxx-xx-xxxx"
                                                                value={this.state.performerDetails?.ssn || null}
                                                                onChange={(e) => {this.onChange("ssn", e.target.value)}}
                                                                isMandatory={true}
                                                                showMandatory={this.state.showErrorField}
                                                            />
                                                        </MDBCol> */}
                                                <MDBCol md={6} className={`${this.state?.performerDetails?.ethnicity_list?.length === 0 ? "searchSelectHeight searchSelectEthnicity" : "searchSelectEthnicity"}${" headingLabel"}${" marginTop"}`} id='formatDropDown'>
                                                    {/* {this.props?.isNew ? */}
                                                    <SearchSelectField
                                                        id={"ethnicity"}
                                                        label={"Ethnicity"}
                                                        isMandatory={true}
                                                        showMandatory={this.state?.showErrorField}
                                                        value={this.state?.performerDetails?.ethnicity_list || []}
                                                        options={this.state?.ethnicityOptions || []}
                                                        // showAsLabel={!this.props?.isEditing}
                                                        // stateLabel={!this.props?.isEditing}
                                                        // limitTags={4}
                                                        onChange={(e, value) => this.onChange('ethnicity_list', value)}
                                                        multiple={true}
                                                        isTalent={true}
                                                    // displayValues={ethnicityValues?.length > 0 ? ethnicityValues?.join() : "--"}
                                                    />
                                                    {/* //    <div>{this.state?.performerDetails?.ethnicity_list?.length > 0 ? this.state?.performerDetails?.ethnicity_list?.map(item => (item?.item.ethnicity)).join("") : "--"}</div> 
                                                    // } */}
                                                    {/* <SelectField
                                                        label={"Ethnicity"}
                                                        isMandatory={true}
                                                        showMandatory={this.state.showErrorField}
                                                        options={this.state.ethnicityOptions}
                                                        value={this.state.performerDetails?.ethnicity || null}
                                                        onChange={(e) => { this.onChange("ethnicity", e.target.value) }}
                                                    /> */}
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="bold-row margin-top padding-left0">
                                                <MDBCol md={4} className="headingLabel">
                                                    <DateField
                                                        label="DOB"
                                                        value={this.state.performerDetails?.birth_date}
                                                        onChange={(e) => this.onChange("birth_date", e.target.value)}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={3}>
                                                    <DateField
                                                        label="DOD"
                                                        value={this.state.performerDetails?.death_date}
                                                        onChange={(e) => this.onChange("death_date", e.target.value)}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={5} className="headingLabel">
                                                    <SelectField
                                                        label="Citizenship"
                                                        options={this.state.countryOptionsCitizen}
                                                        value={this.state.performerDetails?.citizen_of || null}
                                                        onChange={(e) => { this.onChange("citizen_of", e.target.value) }}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol md={4} className="bold-row">
                                                    <SelectField
                                                        label="Gender"
                                                        isMandatory={this.props?.isNew ? true : false}
                                                        showMandatory={this.state.showErrorField}
                                                        options={this.state.genderOptions}
                                                        value={this.state.performerDetails?.gender || null}
                                                        onChange={(e) => { this.onChange("gender", e.target.value) }}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={5} className="bold-row immigrationStatusNew">
                                                    <SearchSelectField
                                                        id={"immigration"}
                                                        label={"Immigration Status"}
                                                        // isMandatory={true}
                                                        // showMandatory={this.props?.showError}
                                                        value={this.state.performerDetails?.immigration_status || []}
                                                        options={this.state.immigrationStatusOptions || []}
                                                        // showAsLabel={!this.props?.isEditing}
                                                        // stateLabel={!this.props?.isEditing}
                                                        // limitTags={4}
                                                        onChange={(e, value) => this.onChange('immigration_status', value)}
                                                    // multiple={true}
                                                    // isTalent={true}
                                                    // displayValues={ethnicityValues?.length > 0 ? ethnicityValues?.join() : "--"}
                                                    />
                                                    {/* <SelectField
                                                        className="immigration-field"
                                                        label="Immigration Status"
                                                        options={this.state.immigrationStatusOptions}
                                                        value={this.state.performerDetails?.immigration_status}
                                                        onChange={(e) => this.onChange("immigration_status", e.target.value)}
                                                    /> */}
                                                </MDBCol>
                                                <MDBCol md={2} className="bold-row new-expires-date">
                                                    <DateField
                                                        label={"Expires"}
                                                        value={this.state.performerDetails?.expiration_date}
                                                        onChange={(e) => this.onChange("expiration_date", e.target.value)}
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </> :
                                    <>
                                        <MDBCol md={8} className="row-height">
                                            <MDBRow className="bold-row">
                                                <MDBCol md={4}>
                                                    <MDBRow className="headingLabel">SSN/Foreign ID</MDBRow>
                                                    {(this.state.performerDetails?.foreign_id!==null || this.state.performerDetails?.ssn!==null)?
                                                    <MDBRow className="talent-content ssn-text">{this.state.performerDetails?.ssn_type == 'ssn' ? `SSN - ${!this.state.isSSNVisible ? this.state.performerDetails?.masked_ssn : this.state.performerDetails?.ssn}` : this.state.performerDetails?.ssn_type == 'fid' ? `Foreign Id-${this.state.performerDetails?.foreign_id != null ? this.state.performerDetails?.foreign_id : ""}` : ''}{this.state.performerDetails?.ssn_type === 'ssn' ? <MDBIcon onClick={() => this.setState({ isSSNVisible: !this.state.isSSNVisible })} icon={!this.state.isSsnVisible ? "eye" : "eye-slash"} /> : null}</MDBRow>:
                                                    <MDBRow className="talent-content ssn-text">--</MDBRow>}
                                                </MDBCol>
                                                <MDBCol md={8}>
                                                    <MDBRow className="headingLabel">Ethnicity</MDBRow>
                                                    <MDBRow className="talent-content">{ethnicityValues?.length > 0 ? ethnicityValues?.join() : "--"}</MDBRow>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="bold-row">
                                                <MDBCol md={4}>
                                                    <MDBRow className="headingLabel">DOB</MDBRow>
                                                    <MDBRow className="talent-content">
                                                        {this.state?.performerDetails?.birth_date != "0000-00-00" && this.state?.performerDetails?.birth_date != "00/00/0000" && this.state?.performerDetails?.birth_date != null ?
                                                            getFormattedDate(this.state.performerDetails?.birth_date) + ' ' + '(' + (this.state?.age ? this.state.age : this.props?.age) + ` yrs.old)`
                                                            : ""}</MDBRow>
                                                </MDBCol>
                                                <MDBCol md={3}>
                                                    <MDBRow className="headingLabel">DOD</MDBRow>
                                                    <MDBRow className={this.state?.performerDetails?.death_date ? 'talent-content redText' : 'talent-content'}>
                                                        {this.state?.performerDetails?.death_date ?
                                                            getFormattedDate(this.state.performerDetails?.death_date)
                                                            : "--"}</MDBRow>
                                                </MDBCol>
                                                <MDBCol md={5}>
                                                    <MDBRow className="headingLabel">Citizenship</MDBRow>
                                                    <MDBRow className="talent-content">{this.state?.performerDetails?.citizen_of}</MDBRow>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow className="bold-row">
                                                <MDBCol md={4}>
                                                    <MDBRow className="headingLabel">Gender</MDBRow>
                                                    <MDBRow className="talent-content">{this.state.performerDetails?.gender}</MDBRow>
                                                </MDBCol>
                                                <MDBCol md={5} className="bold-row immigrationStatus">
                                                    <MDBRow>
                                                        <SearchSelectField
                                                            id={"immigration"}
                                                            label={"Immigration Status"}
                                                            disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                                            // isMandatory={true}
                                                            // showMandatory={this.props?.showError}
                                                            value={this.state.performerDetails?.immigration_status || []}
                                                            options={this.state.immigrationStatusOptions || []}
                                                            // showAsLabel={!this.props?.isEditing}
                                                            // stateLabel={!this.props?.isEditing}
                                                            // limitTags={4}
                                                            onChange={(e, value) => this.onChange('immigration_status', value)}
                                                        // multiple={true}
                                                        // isTalent={true}
                                                        // displayValues={ethnicityValues?.length > 0 ? ethnicityValues?.join() : "--"}
                                                        />
                                                        {/* <SelectField
                                                            className="immigration-field"
                                                            label="Immigration Status"
                                                            options={this.state.immigrationStatusOptions}
                                                            value={this.state.performerDetails?.immigration_status}
                                                            onChange={(e) => this.onChange("immigration_status", e.target.value)}
                                                        /> */}
                                                    </MDBRow>
                                                </MDBCol>
                                                <MDBCol md={3} className="bold-row expires-date">
                                                    <MDBRow>
                                                        <DateField
                                                            label={"Expires"}
                                                            disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                                            value={this.state.performerDetails?.expiration_date}
                                                            onChange={(e) => this.onChange("expiration_date", e.target.value)}
                                                        />
                                                    </MDBRow>
                                                </MDBCol>
                                            </MDBRow>
                                        </MDBCol>
                                    </>}
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={3} className="bold-row">
                                    <DateField
                                        label="Deal Date"
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        isMandatory={true}
                                        showMandatory={this.state.showErrorField}
                                        value={this.state.performerDetails?.deal_date}
                                        onChange={(e) => this.onChange("deal_date", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={2} className="role-no">
                                    <BasicTextField
                                        label="#"
                                        // isMandatory={true}
                                        // showMandatory={this.state.showErrorField}
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        value={this.state.performerDetails?.role_no}
                                        onChange={(e) => this.onChange("role_no", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={3} className="role-field">
                                    <BasicTextField
                                        label="Role"
                                        // isMandatory={true}
                                        // showMandatory={this.state.showErrorField}
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        value={this.state.performerDetails?.role}
                                        onChange={(e) => this.onChange("role", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={3} className="role-field leadCheckbox">
                                    <BasicCheckbox
                                        id="lead"
                                        label="Lead"
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        checked={this.state.performerDetails?.lead_flag === 1 || this.state.performerDetails?.lead_flag === true || false}
                                        align="start"
                                        onChange={(e) => this.onChange("lead_flag", e.target.checked)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={4} className="bold-row" id="rolesDropDown">
                                    <BasicLabel text={"Production Company"} isMandatory={true} />
                                    <SearchSelectField
                                        id="prod_company"
                                        width='100%'
                                        searchSelect={false}
                                        options={this.props?.projectProdCompanies}
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        multiple={false}
                                        isMandatory={true}
                                        showMandatory={this.state.showErrorField}
                                        placeholder={"Production Company"}
                                        value={this.state.performerDetails?.production_company}
                                        onChange={(e, value) => this.onChange("production_company", value)}
                                    // searchText={(e) => this.getCompanyDetails('PRODUCTION_COMPANIES',e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={3} className="bold-row">
                                    <SelectField
                                        label="Union"
                                        className="union"
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        isMandatory={true}
                                        showMandatory={this.state.showErrorField}
                                        options={this.state.unionOptions}
                                        value={this.state.performerDetails?.union}
                                        onChange={(e) => this.onChange("union", e.target.value)}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={3} className="bold-row">
                                    <SelectField
                                        label="SAG Status"
                                        options={this.state.sagStatusOptions}
                                        value={this.state.performerDetails?.sag_status}
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        onChange={(e) => this.onChange("sag_status", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={3} className="bold-row">
                                    <SelectField
                                        label="I9 Status"
                                        options={this.state.i9StatusOptions}
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        value={this.state.performerDetails?.i9_status}
                                        onChange={(e) => this.onChange("i9_status", e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={3} className="bold-row">
                                    <BasicTextField
                                        label="SAG ID"
                                        // isMandatory={true}
                                        // showMandatory={this.state.showErrorField}
                                        value={this.state.performerDetails?.sag_id}
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        onChange={(e) => this.onChange("sag_id", e.target.value)}
                                        showDuplicate={this.state.sagError}
                                        showDuplicateMesg={this.state.sagErrorMessage}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="bold-row loanout-company">
                                <BasicLabel text={"Loanout Company"} />
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md={4} className="bold-row loanout-company">
                                    <SearchFieldWithAddValue
                                        id="loanout_company"
                                        width='100%'
                                        disabled={this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                        searchSelect={true}
                                        options={this.state.loanoutOptions}
                                        multiple={false}
                                        placeholder={"Loanout Company"}
                                        detail_selected={this.state.performerDetails?.loanout_company_state}
                                        value={this.state.performerDetails?.loanout_company_state}
                                        onChange={(e, value) => this.loanOutChange(value)}
                                        validateMethod={this.validateLoanout.bind(this)}
                                        newLabel={'Loanout Company'}
                                    // searchText={(e) => this.getCompanyDetails('PRODUCTION_COMPANIES',e.target.value)}
                                    />
                                </MDBCol>
                                <MDBCol md={3} className={"loanout-fields loanout-state"}>
                                    {/* {this.state.performerDetails?.country_inc?.value === "United States" || this.state.performerDetails?.country_inc?.value === "USA" || this.state.performerDetails?.country_inc?.value === "US" ? */}
                                    <SearchSelectField
                                        id={"loanoutState"}
                                        placeholder="State"
                                        // label={"Immigration Status"}
                                        // isMandatory={true}
                                        // showMandatory={this.props?.showError}
                                        value={this.state.performerDetails?.state_inc || []}
                                        options={this.state.stateOptionsWithoutCode || []}
                                        // showAsLabel={!this.props?.isEditing}
                                        // stateLabel={!this.props?.isEditing}
                                        // limitTags={4}
                                        onChange={(e, value) => this.onChange('state_inc', value)}
                                        disabled={this.state.performerDetails?.loanout_company === null ? true : (this.state.performerDetails?.country_inc?.value == 'United States' || this.state.performerDetails?.country_inc?.value == 'USA' || this.state.performerDetails?.country_inc?.value == 'US' || !this.state.performerDetails?.country_inc?.value) ? false : true}

                                    // multiple={true}
                                    // isTalent={true}
                                    // displayValues={ethnicityValues?.length > 0 ? ethnicityValues?.join() : "--"}
                                    />
                                    {/* <SelectField
                                        //     className="state"
                                        //     placeHolderText="State"
                                        //     options={this.state.stateOptions}
                                        //     value={this.state.performerDetails?.state_inc}
                                        //     onChange={(e) => this.onChange("state_inc", e.target.value)}
                                        //     disabled={this.state.performerDetails?.loanout_company_state != null ? false : true}
                                        // /> 
                                        // :
                                        // <BasicTextField
                                        //     disabled={this.state.performerDetails?.loanout_company_state != null ? false : true}
                                        //     placeholder={"State"}
                                        //     value={this.state.performerDetails?.state_inc}
                                        //     onChange={(e) => this.onChange("state_inc", e.target.value)}
                                        // />} */}
                                </MDBCol>
                                <MDBCol md={3} className="loanout-fields loanout-country">
                                    <SearchSelectField
                                        id={"loanoutCountry"}
                                        placeholder="Country"
                                        // label={"Immigration Status"}
                                        // isMandatory={true}
                                        // showMandatory={this.props?.showError}
                                        value={this.state.performerDetails?.country_inc || []}
                                        options={this.state.countryOptions || []}
                                        // showAsLabel={!this.props?.isEditing}
                                        // stateLabel={!this.props?.isEditing}
                                        // limitTags={4}
                                        onChange={(e, value) => this.onChange('country_inc', value)}
                                        disabled={this.state.performerDetails?.loanout_company === null}
                                    // multiple={true}
                                    // isTalent={true}
                                    // displayValues={ethnicityValues?.length > 0 ? ethnicityValues?.join() : "--"}
                                    />
                                    {/* <SelectField
                                        className={this.state.performerDetails?.country_inc === "United States" || this.state.performerDetails?.country_inc === "USA" || this.state.performerDetails?.country_inc === "US" ? "country" : "country-text"}
                                        placeHolderText="Country"
                                        options={this.state.countryOptions}
                                        value={this.state.performerDetails?.country_inc}
                                        onChange={(e) => this.onChange("country_inc", e.target.value)}
                                        disabled={this.state.performerDetails?.loanout_company_state != null ? false : true}
                                    /> */}
                                </MDBCol>
                                <MDBCol md={2} className="loanout-fields">
                                    <BasicTextField
                                        className="zip"
                                        placeholder="Loanout No."
                                        value={this.state.performerDetails?.loanout_no}
                                        onChange={(e) => (this.state.performerDetails?.country_inc?.value == 'United States' || this.state.performerDetails?.country_inc?.value == 'USA' || this.state.performerDetails?.country_inc?.value == 'US') ? this.onChange("loanout_no", formatEIDNum(e.target.value)) : this.onChange("loanout_no", e.target?.value)}
                                        // onChange={(e) => this.onChange("loanout_no", e.target.value)}
                                        fieldValid={this.state.performerDetails?.loanout_no ? ((this.state.performerDetails?.country_inc?.value == 'United States' || this.state.performerDetails?.country_inc?.value == 'USA' || this.state.performerDetails?.country_inc?.value == 'US') ? (this.state.performerDetails?.loanout_no?.length != 9 || this.state.performerDetails?.loanout_no?.length == 9 ? !checkFederalIDNum(this.state.performerDetails?.loanout_no) ? true : false : false) : false) : false}
                                        inValidInput={Messages.invalidInput}
                                        disabled={this.state.performerDetails?.loanout_company != null ? false : true}
                                        showDuplicate={this.state.loanoutError}
                                        showDuplicateMesg={this.state.loanoutErrorMesg}
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className="bold-row performerNotes">
                                    <BasicLabel text={"Performer Notes"} />
                                    <RichTextEditor id={`performerNotes`} 
                                    value={this.state.performerDetails?.comments} 
                                    readOnly = {this.props?.userContext?.user_profile?.user_role_name !== "Admin"}
                                    onChange={(value) => this.handleOnChangeText(value)}>
                                    </RichTextEditor>
                                    {/* <BasicTextArea
                                        label="Performer Notes"
                                        row={3}
                                        // placeholder="Comments go here;"
                                        value={this.state.performerDetails?.comments}
                                        onChange={(e) => this.onChange("comments", e.target.value)}
                                    /> */}
                                </MDBCol>
                            </MDBRow>
                            <br />
                            <MDBRow>
                                <MDBCol md={8}></MDBCol>
                                <MDBCol md={2} className='d-flex justify-content-end'>
                                     {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                    <BasicButton
                                        text={this.state?.postingTalentPerformer ? <CircularProgress color="inherit" size={20} /> : this.props?.isNew ? this.props?.primaryButtonText : "Save"}
                                        variant="contained"
                                        type="inline"
                                        icon={"save"}
                                        disabled={this.state.disableSave}
                                        onClick={() => this.props?.isNew ? this.state.performerDetails?.ssn?.length < 9 || this.state.performerDetails?.ssn?.length < 11 ? this.setState({ invalidSSN: true }) : this.postTalentPerformer(this.state.performerDetails) : this.postTalentPerformer(this.state.performerDetails)}
                                    />}
                                </MDBCol>
                                <MDBCol md={2} className='d-flex justify-content-end'>
                                    <BasicButton
                                        text="Cancel"
                                        variant="outlined"
                                        type="inline"
                                        icon="times"
                                        onClick={() => this.props?.onClose()}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default withUserContext(TalentPerformer);